<template>
  <span>
    <v-row>
      <v-col md="5">
        <v-text-field
          v-model="service.name"
          :disabled="disabled"
          :label="$trans('service_name')"
          hide-details="auto"
          validate-on-blur
          outlined
          :rules="[rules.required, rules.maxChars(255)]"
          class="required-star"
          @input="onChange"
        />
      </v-col>
      <v-col v-if="displayedVariants.length === 0" md="2" cols="12">
        <v-select
          v-model="service.duration"
          :disabled="disabled"
          outlined
          hide-details="auto"
          :items="durationList"
          :item-text="renderDuration"
          item-value="duration"
          :label="$trans('duration')"
          :rules="[rules.required, rules.maxNumber]"
          class="required-star"
          @change="onChange"
        />
      </v-col>
      <v-col
        v-if="displayedVariants.length === 0 && !serviceIsFree"
        md="2"
        cols="12"
      >
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-text-field
              v-model="service.price"
              :disabled="disabled"
              type="number"
              hide-details="auto"
              validate-on-blur
              outlined
              :rules="[
                rules.required,
                rules.maxNumber,
                rules.minNumber(service.price, 0),
              ]"
              class="required-star"
              @input="onChange"
              v-on="on"
            >
              <template #label>
                <span>{{
                  $trans("price_in_currency", {
                    currency: $config("currency").toUpperCase(),
                  })
                }}</span>
              </template>
            </v-text-field>
          </template>
          {{ $trans("price_additional_info") }}
        </v-tooltip>
      </v-col>
      <v-col md="2" cols="12" class="d-flex align-center">
        <v-checkbox
          v-model="serviceIsFree"
          :disabled="disabled"
          :label="$trans('service_free_option')"
          @change="onChange"
        />
      </v-col>
      <v-col v-if="displayedVariants.length" md="7" />
    </v-row>

    <template v-if="displayedVariants.length">
      <v-row v-for="item in displayedVariants" :key="item.id" align="baseline">
        <v-col md="5" offset-md="1">
          <v-text-field
            v-model="item.name"
            :disabled="disabled"
            :label="$trans('variant_name')"
            validate-on-blur
            hide-details="auto"
            outlined
            :rules="[rules.required, rules.maxChars(255)]"
            class="required-star"
            @input="onChange"
          />
        </v-col>
        <v-col md="2" cols="12">
          <v-select
            v-model="item.duration"
            :disabled="disabled"
            outlined
            hide-details="auto"
            :items="durationList"
            :item-text="renderDuration"
            item-value="duration"
            :label="$trans('duration')"
            :rules="[rules.required, rules.maxNumber]"
            class="required-star"
            @change="onChange"
          />
        </v-col>
        <v-col md="3" cols="12" class="d-flex align-center">
          <v-text-field
            v-if="!serviceIsFree"
            v-model="item.price"
            :disabled="disabled"
            validate-on-blur
            hide-details="auto"
            type="number"
            outlined
            :rules="[rules.required, rules.maxNumber]"
            class="required-star mr-2"
            @input="onChange"
          >
            <template #label>
              <span>{{
                $trans("price_in_currency", {
                  currency: $config("currency").toUpperCase(),
                })
              }}</span>
            </template>
          </v-text-field>
          <v-btn icon :disabled="disabled" @click="removeVariant(item)">
            <v-icon> $trash-default </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <v-row>
      <v-col :offset-md="variantsToSend.length ? 1 : 0">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              outlined
              :disabled="disabled"
              color="light"
              @click="addNewVariant"
              v-on="on"
            >
              <span class="dark--text"> {{ $trans("add_variant") }}</span>
            </v-btn>
          </template>
          {{ $trans("service_types_additional_info") }}
        </v-tooltip>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { serviceDurationList } from "@/lib/calendesk-js-library/tools/helpers";
import duration from "@/lib/calendesk-js-library/filters/duration";
import {
  minChars,
  maxChars,
  maxNumber,
  required,
  minNumber,
} from "@/lib/calendesk-js-library/forms/validators";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "ServiceNameAndVariants",
  mixins: [planActions],
  props: {
    data: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      durationList: serviceDurationList(),
      idCount: 0,
      serviceIsFree: false,
      variantsToSend: [],
      rules: {
        required,
        minChars,
        maxChars,
        maxNumber,
        minNumber,
      },
      service: {
        name: null,
        duration: null,
        price: null,
        id: null,
      },
    };
  },
  computed: {
    displayedVariants() {
      return this.variantsToSend.filter((x) => !x.toRemove);
    },
  },
  created() {
    this.$watch("data", this.updateServiceData);
  },
  methods: {
    updateServiceData(data) {
      this.service = data.service;
      this.variantsToSend = JSON.parse(JSON.stringify(data.types));
      this.reloadPrices();
    },
    reloadPrices() {
      if (this.variantsToSend && this.variantsToSend.length > 0) {
        let hasPrice = false;

        this.variantsToSend.forEach((variant) => {
          if (variant.price > 0) {
            hasPrice = true;
          }
        });

        this.serviceIsFree = !hasPrice;
      } else if (
        this.service &&
        (this.service.price === 0 || !this.service.price)
      ) {
        this.serviceIsFree = true;
      }
    },
    onChange() {
      if (this.serviceIsFree) {
        this.service.price = 0;
      }

      if (
        this.serviceIsFree &&
        this.variantsToSend &&
        this.variantsToSend.length > 0
      ) {
        this.variantsToSend.forEach((variant) => {
          variant.price = 0;
        });
      }

      const data = {
        service: this.service,
        types: this.variantsToSend,
      };

      this.$emit("change", this.$helpers.cloneObject(data));
    },
    removeVariant({ id }) {
      const index = this.variantsToSend.findIndex(
        (variant) => variant.id === id
      );
      if (index !== -1) {
        if (this.variantsToSend[index].isNew) {
          this.variantsToSend.splice(index, 1);
        } else {
          // overcome reactivity caveat
          const newVal = this.variantsToSend[index];
          newVal.toRemove = true;
          newVal.serviceId = this.service.id;
          this.$set(this.variantsToSend, index, newVal);
        }
        this.onChange();
      }
    },
    addNewVariant() {
      if (this.canUseServiceVariants) {
        this.idCount += 1;
        const variant = {
          name: null,
          duration: null,
          price: null,
          id: `${this.idCount}temp`,
          serviceId: this.service.id,
          isNew: true,
        };
        this.variantsToSend.push(variant);
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    renderDuration(value) {
      return duration(value.duration);
    },
  },
};
</script>
